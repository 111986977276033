import { ITheme } from '@/shared/styles/MuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles'

type ICalendarStyleProps = {
    isPlaceholder?: boolean
}

export const useInfiniteListStyles = makeStyles({
    infiniteList: {
        // override inline style from react-infinite-scroll-component showing scrollbar
       overflow: 'hidden !important',
    },
})
export const useCalendarStyles = makeStyles((theme: ITheme) => createStyles({
    leftSide: {
        paddingBottom: theme.spacing(4),
    },
    placeholderWrapper: {
        minHeight: 250,
        borderRadius: 8,
        backgroundColor: theme.props.sectionMode.card,
        width: '100%',
        '&.right': {
            marginLeft: 72,
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(4),
            },
        },
        '&.left': {
            marginRight: 72,
        }
    },
    boxWrapper: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        // show smaller border
        [theme.breakpoints.down('sm')]: {
            borderBottom: `1.5px solid ${theme.props.sectionMode.card}`,
        },
    },
    boxWrapperMaxWidth: {
        maxWidth: 267
    },
    borderWrapper: {
        width: '100%',
        // show border crossing vertical line
        [theme.breakpoints.up('md')]: {
            borderBottom: `1.5px solid ${theme.props.sectionMode.card}`,
        },
        '&.right': {
            paddingLeft: 72,
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(4),
            },
        },
        '&.left': {
            display: 'flex',
            paddingRight: 72,
        }
    },
    verticalLine: {
        borderLeft: (props: ICalendarStyleProps) => `1px solid ${getVerticalLineColor(theme, props)}`,
        position: 'relative',
        paddingBottom: theme.spacing(4),
        '&:before': {
            position: 'absolute',
            top: 'calc(50% - 8px)',
            left: -8.5,
            content: '""',
            height: '16px',
            width: '16px',
            backgroundColor: (props: ICalendarStyleProps) => getVerticalLineColor(theme, props),
            borderRadius: '50%',
        }
    },
    viewMore: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'start',
        }
    },
    spacer: {
        paddingBottom: theme.spacing(4)
    },
    score: {
        // padding: theme.spacing(0, 3),
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center'
    },
    listRoot: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2)
        }
    },
    teamRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    teamSpacing: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
        textAlign: 'center'
    },
    team2line: {
        // width: 100
    },
    trainingTeam: {
        padding: theme.spacing(0, 2),
    },
    navigationButton: {
        minWidth: '80px',
        width: '102px',
        '& span': {
            margin: 0,
        }
    },
    noWrap: {
        whiteSpace: 'nowrap'
    },
    groupSelector: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    adRoot: {
        width: '100%',
        display: 'flex',
        margin: '8px 0',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
            margin: '8px 0 24px',
        }
    },
    cancelledText: {
        textDecoration: 'line-through'
    },
    scoreParagraph: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    noPointerEvents: {
        pointerEvents: 'none'
    }
}))

function getVerticalLineColor(theme: ITheme, props: ICalendarStyleProps) {
    const textColor = theme.props.sectionMode.text_background
    if(props.isPlaceholder) {
        return theme.props.sectionMode.card
    }
    return textColor
}