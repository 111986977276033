import makeStyles from '@material-ui/core/styles/makeStyles';


export const useSectionCardMediaStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none'
    },
    root: {
        height: '100%',
        '& > :first-child': {
            height: '100%'
        },
        '&--featured': {
            height: 'unset'
        }
    },
    cardVideo: {
        paddingTop: '56.25%',
        position: 'relative',
        '& > div': {
            top: 0,
            left: 0,
            position: 'absolute',
            width: '100%',
            height: '100%',
        }
    },
    player: {
    },
    cardImage: {
        position: 'relative',
        width: '100%',
        height: '100%',
        minHeight: 150,
        [theme.breakpoints.up('md')]: {
            height: 150,
            '&--featured': {
                height: 'unset'
            },
            '&--roster': {
                height: 'auto'
            },
            '&--sponsor': {
                height: 176
            },
        },
        [theme.breakpoints.down('sm')]: {
            '&--roster': {
                height: 'auto'
            },
            '&--sponsor': {
                height: 148
            },
        },
        '&--no-scaling': {
        },
        '&--sponsor': {
           backgroundColor: 'white'
        },
        '&--p-3': {
            padding: theme.spacing(3),
        }
    },
    cardInnerRoot: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    cardImageContainer: {
    },
    media: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        '&--no-scaling': {
        },
        '&--roster': {
            aspectRatio: '3/4',
        },
        '&--sponsor': {
            objectFit: 'contain'
        },
        '&--featured': {
            aspectRatio: '16/9',
        },
    },
    icon: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: 0,
        '&--icon-left': {
            left: 0
        },
        '&--icon-right': {
            right: 0
        },
        '&--icon-center': {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        '&--icon-jersey': {
            left: 0,
            top: 24,
            padding: theme.spacing(0),
        }
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(4, 4, 5, 4),
        '& p, & h4': {
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    cardDescription: {
    },
    cardTitle: {
        paddingBottom: theme.spacing(4),
    },
    omitWebKitClamp: {
        '-webkit-line-clamp': 'auto !important'
    }
}))

export const useMediaPreviewStyles = makeStyles(() => ({
    player: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    playerWrapper: {
        position: 'relative',
        paddingTop: '56.25%',
    }
}))


export const useLongCardStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none'
    },
    cardRoot: {
        height: 101,
        [theme.breakpoints.down('md')]: {
            height: 93
        },
        '& > :first-child': {
            height: '100%'
        },
    },
    contentSpacer: {
        padding: theme.spacing(2)
    },
    image: {
        width: 129,
        height: '100%',
        objectFit: 'cover',
        display: 'block',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    contentRoot: {
        display: 'flex',
        height: '100%'
    },
    mediaRoot: {
        position: 'relative',
        height: '100%'
    },
    mediaIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    descriptionRoot: {
        display: 'flex',
        flexDirection: 'column',
        '& p, & h4': {
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }
}))
