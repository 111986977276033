import { Box } from '@material-ui/core';
import Card from '@/shared/components/Card';
import { Headline4, Paragraph2 } from '@/shared/components/Typography'
import ImgLoader from '@/shared/components/Img/ImgLoader';
import ClubResponsiveFallback from '@/shared/components/Img/ClubResponsiveFallback';
import { ReactNode } from 'react';
import { useLongCardStyles } from './styles';
import ElementLink from '@/shared/components/ElementLink/ElementLink';

type IVideoCardProps = {
    link: string;
    media: string
    icon?: ReactNode
    title: string
    description?: string
    slug?: ReactNode
    useNextImage?: boolean
}
export default function SectionLongCard(props: IVideoCardProps): JSX.Element {
    const { media, link, icon, title, description, slug, useNextImage } = props
    const classes = useLongCardStyles()
    return (
        <ElementLink link={link}>
            <article className={classes.cardRoot}>
                <Card>
                    <div className={classes.contentRoot}>
                        <div className={classes.mediaRoot}>
                            <ImgLoader
                                src={media}
                                className={classes.image}
                                alt={title}
                                failoverComponent={<ClubResponsiveFallback className={classes.image}/>}
                                useNextImage={useNextImage}
                            />
                            {icon && (
                                <div className={classes.mediaIcon}>
                                    {icon}
                                </div>
                            )}
                        </div>
                        <Box className={classes.descriptionRoot} px={2}>
                            {slug && (
                                <Box py={2}>
                                    {slug}
                                </Box>
                            )}
                            <div>
                                <Box pb={2}>
                                    <Headline4>
                                        {title}
                                    </Headline4>
                                </Box>
                                {description && (
                                    <Box pb={2}>
                                        <Paragraph2>
                                            {description}
                                        </Paragraph2>
                                    </Box>
                                )}
                            </div>
                        </Box>
                    </div>
                </Card>
            </article>
        </ElementLink>
    )
}