
import { useEffect, useRef } from 'react'
import InfiniteScroll, { Props as InfiniteScrollProps } from 'react-infinite-scroll-component'

function CustomInfiniteScroll(props:  InfiniteScrollProps) {
    const ref = useRef<InfiniteScroll>(null)
    useEffect(() => {
        if(ref.current) {
            ref.current.onScrollListener(null)
        }
    }, [])
    return (
        <InfiniteScroll hasChildren ref={ref} {...props}/>
    )

}

export default CustomInfiniteScroll