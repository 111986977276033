import ImgLoader from '@/shared/components/Img/ImgLoader'
import applyMods from '@/shared/utils/applyMods'
import { useSquareStyles } from './styles'
import { ReactNode } from 'react'

type ISquareProps = {
    mods?: string,
    media?: string,
    failOverImage?: string,
    failoverComponent?: ReactNode,
    imgScale?: string,
    isVideo?: boolean
}
export default function Square(props: ISquareProps):JSX.Element {
    const { media, mods: modsProp = '', failOverImage, failoverComponent, imgScale = '100%', isVideo } = props
    const classes = useSquareStyles()
    const mods = (media || failoverComponent) ? modsProp.split('|').filter(Boolean).concat(['media']).join('|') : modsProp

    if (isVideo) {
        return (
            <div className={classes.thumbnailVideo}></div>
        )
    }
    return (
        <div className={applyMods(classes.thumbnail, mods)} style={{ paddingBottom: imgScale }}>
            {(media || failoverComponent) && <ImgLoader src={media} className={classes.media} failoverImage={failOverImage} failoverComponent={failoverComponent}/>}
        </div>
    )
}