import { getClubFallbackImages } from '@/shared/utils/getClubUrl';
import { useClub } from 'contexts/club';
import { CSSProperties } from 'react';
import ResponsiveImage from './ResponsiveImage';

type IClubResponsiveFallbackProps = {
    className?: string
    style?: CSSProperties
}
export default function ClubResponsiveFallback(props: IClubResponsiveFallbackProps) {
    const { club } = useClub()

    const sources = getClubFallbackImages(club)
    const mainSource = sources[sources.length - 1]
    return (
        <ResponsiveImage
            sources={sources}
            src={mainSource.src}
            {...props}
        />
    )
}