import React, { ReactNode } from 'react'
import Grid, { GridSize } from '@material-ui/core/Grid';
import Card from '@/shared/components/Card';
import Hidden from '@material-ui/core/Hidden'
import { Headline3 } from '@/shared/components/Typography';
import { useEmptyPlaceholderStyles } from './styles';
import Square from './Square';
import Box from '@material-ui/core/Box';

export type IEmptyPlaceholderProps = {
    fullScreenSize: number,
    smallScreenSize: number,
    title: ReactNode,
    mods?: string,
    isVideo?: boolean
}

function EmptyPlaceholder(props: IEmptyPlaceholderProps): JSX.Element {
    const { fullScreenSize, smallScreenSize, title, mods, isVideo } = props
    const xsList = [...Array(smallScreenSize)].map((_, i) => i+1)
    const mdList = [...Array(fullScreenSize)].map((_, i) => i+1)
    const classes = useEmptyPlaceholderStyles()
    const mdSize = Math.floor(12/fullScreenSize) as GridSize
    const xsSize = Math.floor(12/smallScreenSize) as GridSize

    return (
        <Box>
            <div className={classes.title}>
                <Headline3>{title}</Headline3>
            </div>
            <Grid container spacing={2}>
                <Hidden mdUp>
                    {xsList.map(renderCard)}
                </Hidden>
                <Hidden smDown>
                    {mdList.map(renderCard)}
                </Hidden>
            </Grid>
        </Box>
    );

    function renderCard(key: number) {
        return (
            <Grid key={key} item md={mdSize} sm={xsSize} xs={xsSize}>
                <Card>
                    <Square mods={mods} isVideo={isVideo}/>
                </Card>
            </Grid>
        )
    }
}

export default EmptyPlaceholder