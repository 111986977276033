import { ReactNode } from 'react'
import Card from '@/shared/components/Card';
import { useSectionCardMediaStyles } from './styles';
import { Headline3, Headline4, Paragraph2 } from '@/shared/components/Typography'
import applyMods from '@/shared/utils/applyMods';
import ImgLoader from '@/shared/components/Img/ImgLoader';
import ClubResponsiveFallback from '@/shared/components/Img/ClubResponsiveFallback';
import ElementLink from '@/shared/components/ElementLink/ElementLink';

type ISectionCardMediaProps = {
    title: string;
    description: ReactNode;
    media: string;
    mods?: string; // for icon
    icon?: ReactNode;
    onClick?():void;
    mediaMods?: string, // for image
    link?: string,
    target?: string,
    failOverImage?: string
    useFailOverComponent?: boolean
    failOverComponent?: ReactNode,
    openLinkNewTab?: boolean;
    isArticle?: boolean
    bigTitle?: boolean
    useNextImage?: boolean,
    additionalParagraphClass?: string
}

export default function SectionCardMedia(props: ISectionCardMediaProps): JSX.Element {
    const classes = useSectionCardMediaStyles()
    const {
        mods, icon, media, title, description, mediaMods, link,
        useFailOverComponent = true, failOverComponent, openLinkNewTab,
        failOverImage, isArticle, target, bigTitle, useNextImage, additionalParagraphClass
    } = props

    const child = (
        <Card>
            <div className={classes.cardInnerRoot}>
                <div className={classes.cardImageContainer}>
                    <div className={applyMods(classes.cardImage, mediaMods)}>
                        <ImgLoader
                            src={media}
                            failoverImage={failOverImage}
                            className={applyMods(classes.media, mediaMods)}
                            failoverComponent={useFailOverComponent && (failOverComponent ||
                                <ClubResponsiveFallback
                                    className={applyMods(classes.media, mediaMods)}
                                />
                            )}
                            useNextImage={useNextImage}
                        />
                        {icon && (
                            <div className={applyMods(classes.icon, mods)}>
                                {icon}
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes.cardContent}>
                    <div className={classes.cardTitle}>
                        {bigTitle ? <Headline3>{title}</Headline3> : <Headline4>{title}</Headline4>}
                    </div>
                    <div className={classes.cardDescription}>
                        <Paragraph2 className={additionalParagraphClass}>
                            {description}
                        </Paragraph2>
                    </div>
                </div>
            </div>
        </Card>
    )
    return (
        <ElementLink openLinkNewTab={openLinkNewTab} link={link || '#'} target={target}>
            {isArticle ? (
                <article className={applyMods(classes.root, mediaMods)}>
                    {child}
                </article>
            ): (
                <div className={applyMods(classes.root, mediaMods)}>
                    {child}
                </div>
            )}
        </ElementLink>
    )
}